import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DefaultSettingService } from 'src/app/core/services/default-setting.service';
import { LoginForm } from 'src/app/account/models';
import { ChangeData } from "ngx-intl-tel-input";
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/user/user.service';
import { SessionStorageService } from '../../storage/session-storage.service';

@Component({
  selector: 'app-guest-info',
  templateUrl: './guest-info.component.html',
  styleUrls: ['./guest-info.component.scss']
})
export class GuestInfoComponent implements OnInit {
  @Output() onClickSubmitGuestInfo = new EventEmitter();

  fgroup: UntypedFormGroup = this.fb.group({
    name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    mobileNo: ['', Validators.compose([Validators.required])],
    email: ['', [Validators.required, Validators.email, Validators.pattern(this.utilsService.emailPattern)]],
  });

  telInpSetting = DefaultSettingService.telInpSetting;
  invalidMobileFormat: boolean = false;
  isLoggedIn: boolean = false;
  guestInfo$: Subscription;
  isUpdate: boolean = false;
  requirField : boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private utilsService: UtilsService,
    private userService: UserService,
    private sessionStorageService: SessionStorageService,
  ) {
    this.getFormControl('mobileNo').valueChanges.subscribe((dt) => {
      this.invalidMobileFormat = false;
    })
  }

  async ngOnInit() {
    this.guestInfo$ = this.userService.getGuestInfo().subscribe((dt: any) => {
      if(dt){
        this.getFormControl('name').setValue(dt?.name ?? '');
        this.getFormControl('mobileNo').setValue(dt?.mobileNo ?? '');
        this.getFormControl('email').setValue(dt?.email ?? '');

        this.isUpdate = true;
      }else if(this.sessionStorageService.getItem('guestInfo')){
        let localGuestInfo =  JSON.parse(this.sessionStorageService.getItem('guestInfo'));

        this.getFormControl('name').setValue(localGuestInfo?.name ?? '');
        this.getFormControl('mobileNo').setValue(localGuestInfo?.mobileNo ?? '');
        this.getFormControl('email').setValue(localGuestInfo?.email ?? '');
        this.userService.guestInfo.next({ name : localGuestInfo.name, email: localGuestInfo.email, mobileNo : localGuestInfo.phoneNo});
        this.isUpdate = true;
      }
    });

  }

  private getFormControl(formControlName: string) {
    return this.fgroup.controls[formControlName];
  }

  get fg() { return this.fgroup; }
  get f() { return this.fg.controls; }

  private formatPhoneNo(phoneObj: ChangeData) {
    return phoneObj.e164Number ? phoneObj.e164Number.replace(phoneObj.dialCode || '', '') : '';
  }

  async onClickContinue() {
    let name = this.getFormControl('name').value;
    let mobileNo = this.getFormControl('mobileNo').value;
    let email = this.getFormControl('email').value;

    if (name && mobileNo && email) {
      const mobile = mobileNo && mobileNo.e164Number;
      let invalidPhoneNo: boolean = await this.utilsService.phoneNoValidation(mobile);
      if (invalidPhoneNo) {
        this.invalidMobileFormat = true;
        return;
      } else if (this.f.email.errors?.pattern){
        return;
      }
       else {
        const guestInfo: LoginForm = {
          name: name,
          phoneNo: mobile.replace('+', '') + this.formatPhoneNo(mobile),
          email: email,
        }
        this.onClickSubmitGuestInfo.emit(guestInfo);
      }
    }else {
      this.requirField = true;
    }
  }

  ngOnDestroy() {
    this.guestInfo$?.unsubscribe();
  }
}
