<div class="guestInfo-container">
  <img src="assets/user.svg">
  <h1>{{'guest.info.title' | translate}}</h1>

  <form [formGroup]="fgroup">
    <div class="name-field">
      <label>{{ 'input.label.19' | translate }}<span class="mandatory">*</span></label>
      <input type="text" pInputText formControlName="name" placeholder="{{ 'input.placeholder.7' | translate }}" #name>

      <div class="invalid-feeback" *ngIf="!name.value && requirField">
        <i class="oda-alert"></i>
        {{ 'qr.ref.no.required' | translate }}
      </div>
    </div>

    <div class="mobileNo-field">
      <label>{{ 'input.label.1' | translate }}<span class="mandatory">*</span></label>
      <ngx-intl-tel-input [cssClass]="" [enableAutoCountrySelect]="telInpSetting.enableAutoCountrySelect"
        [customPlaceholder]="telInpSetting.customPlaceholder | translate"
        [enablePlaceholder]="telInpSetting.enablePlaceholder" [searchCountryFlag]="telInpSetting.searchCountryFlag"
        [searchCountryField]="telInpSetting.searchCountryField" [selectFirstCountry]="telInpSetting.selectFirstCountry"
        [phoneValidation]="telInpSetting.phoneValidation" [inputId]="'phoneNo'"
        [separateDialCode]="telInpSetting.separateDialCode" [preferredCountries]="telInpSetting.preferredCountries"
        [selectedCountryISO]="telInpSetting.selectedCountryISO" name="phone" formControlName="mobileNo" #phone_number>
      </ngx-intl-tel-input>

      <div class="invalid-feeback" *ngIf="!phone_number.value && requirField">
        <i class="oda-alert"></i>
        {{ 'qr.ref.no.required' | translate }}
      </div>

      <div class="invalid-feeback" *ngIf="invalidMobileFormat">
        <i class="oda-alert"></i>
        {{ 'login.form.mobile.number.invalid' | translate }}
      </div>
    </div>

    <div class="email-field">
      <label>{{ 'input.label.8' | translate }}<span class="mandatory">*</span></label>
      <input type="text" pInputText formControlName="email" placeholder="{{ 'input.placeholder.8' | translate }}"
        #email>

      <div class="invalid-feeback" *ngIf="f.email.errors?.pattern">
        <i class="oda-alert"></i>
        {{ 'refund.return.email.error.email.invalid' | translate }}
      </div>

      <div class="invalid-feeback" *ngIf="!email.value && requirField">
        <i class="oda-alert"></i>
        {{ 'qr.ref.no.required' | translate }}
      </div>
    </div>

    <button class="continue-btn" (click)="onClickContinue()"
      [ngClass]="{'disabled-button': !name.value || !phone_number.value || !email.value}">{{ isUpdate ?
      ('address.form.button.update' | translate ) : ('button.continue' | translate ) }}</button>
  </form>
</div>
